.testimonials {
    position: relative;
    float: left;
    width: calc(100% - 20px);
    padding: 10px;
}

.testimonials-quote {
    position: relative;
    float: left;
    width: calc(100% - 30px);
    margin: 0px 0px 0px 30px;
}

.testimonials-quote-text {
    font-style: italic;
}

.testimonials-quote-text:before {
    content: '"';
}

.testimonials-quote-text:after {
    content: '"';
}

.testimonials-quote-author:before {
    content: ' - ';
}

@media screen and (min-width: 850px) {
    .testimonials {
        width: 830px;
        margin-left: calc(50% - 425px);
    }

    .testimonials-quote {
        width: calc(50% - 30px);
    }
}
