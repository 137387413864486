.project {
    position: relative;
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

.project-banner {
    position: relative;
    float: left;
    width: 100%;
}

.project-description, .project-roles {
    position: relative;
    float: left;
    width: calc(100% - 40px);
    padding: 0px 20px 0px 20px;
}

.project-roles {
    text-align: center;
    font-size: 80%;
}

.project-description a {
    color: #ff0083;
    font-weight: bold;
    text-decoration: underline;
}

@media screen and (min-width: 800px) {
    .project {
        width: 800px;
        margin-left: calc(50% - 400px);
    }
}
