.socials {
    position: relative;
    float: left;
    max-width: 100%;
    width: 256px;
    margin-left: calc(50% - 128px);
}

.socials a {
    margin: 0px;
    padding: 0px;
}

.socials-logo {
    position: relative;
    float: left;
    width: 50%;
    height: auto;
    transition: transform 0.1s;
    z-index: 1;
}

.socials-logo:hover {
    z-index: 2;
    transform: scale(1.1);
}
