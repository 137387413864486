.embeddables {
    position: relative;
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

.embeddable-placeholder {
    position: relative;
    float: left;
    width: 100%;
}

@media screen and (min-width: 800px) {
    .embeddables {
        width: 800px;
        margin-left: calc(50% - 400px);
    }
}
