.loader {
    position: relative;
    float: left;
    width: 100%;
    height: 10px;
}

.loader-mover {
    position: absolute;
    top: 0px;
    bottom: 0px;
    animation-name: move;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    background:#ff0083;
}

@keyframes move {
    0% { left: 0%; right: 80%; }
    25% { left: 0%; right: 20%; }
    50% { left: 80%; right: 0%; }
    75% { left: 20%; right: 0%; }
    100% { left: 0%; right: 80%; }
}