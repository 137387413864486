#Navigator {
    position: relative;
    float: left;
    width: calc(100% - 40px);
    padding: 20px;
    text-align: center;
}

.navigator-item {
    text-align: center;
    background: none;
    border: none;
    padding: 10px;
    width: calc(33% - 20px);
    cursor: pointer;
    transition: transform 0.1s;
}

.navigator-item:hover {
    transform: scale(1.1);
}

.navigator-item.highlight {
    font-weight: bold;
    color: #ff0083;
}

@media screen and (orientation: landscape) {
    .navigator-item {
        width: initial;
    }
}
