.embeddable {
    position: relative;
    float: left;
    width: 100%;
}

.embeddable h4 {
    position: relative;
    float: left;
    width: calc(100% - 40px);
    margin: 0px;
    padding: 20px;
    text-align: center;
}

.embeddable iframe {
    position: relative;
    float: left;
    width: 100%;
}
