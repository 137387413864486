.projects {
    position: relative;
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

.project-preview {
    position: relative;
    float: left;
    width: 50%;
    height: auto;
    transition: transform 0.1s;
}

.project-preview:hover {
    transform: scale(1.05);
}

@media screen and (min-width: 600px) {
    .projects {
        max-width: 100%;
        width: 512px;
        margin-left: calc(50% - 256px);
    }
}
